<template>
  <BCard>
    <div class="text-black text-[20px] font-semibold mb-2 d-flex">
      <button class="button-primary text-white d-flex mr-1 h-fit px-[5px]">
        <span
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
          @click="$router.back()"
        />
      </button>
      <span class="text-10 text--dark">Detail Churn Partner</span>
      <div class="d-flex flex-col ml-auto text-right">
        <span class="text-muted text-[12px]">Nama partner</span>
        <span class="text-[16px]">{{ partnerName }}</span>
      </div>
    </div>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(talent_name)="data">
            <div class="font-semibold text-7">
              {{ data.item.talent_name }}
            </div>
            <div class="text-6">
              {{ data.item.staff_name }}
            </div>
          </template>
          <template #cell(reason_quit)="data">
            <div
              :id="`reason-${data.item.id+3}`"
              v-b-tooltip.hover.top="data.item.reason_quit"
              class="reason w-fit font-italic"
            >
              {{ data.item.reason_quit.length > 35 ? data.item.reason_quit.substr(0, 35) + '...' : data.item.reason_quit }}
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableDetail } from './config'

export default {
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      fields: tableDetail,
      alertError,
      alertSuccess,
      partnerName: '',
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getDetailData() {
      this.offset = 0
      const url = `v1/churn_partners/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.items = await data
          if (data && data?.length) {
            this.partnerName = data[0].partner_name
          }
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>

<style scoped>
.reason:hover {
  color: #F95031;
  cursor: pointer;
  font-weight: 500;
}
</style>
