import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

// eslint-disable-next-line import/prefer-default-export
export const tableField = [
  {
    key: 'partner_name',
    label: 'Nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'no_partner',
    label: 'No Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'created_at',
    label: 'Tanggal Pengajuan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
  },
]

export const tableDetail = [
  {
    key: 'talent_name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'unassign_date',
    label: 'Tanggal Unassign',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => (value ? DAY_MONTH_YEAR(value) : ''),
  },
  {
    key: 'duration',
    label: 'Durasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'reason_quit',
    label: 'Alasan unassign',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
]
